// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  main: {
    liveOrders: path(ROOTS_DASHBOARD, '/live-orders'),
    dashboardKpi: path(ROOTS_DASHBOARD, '/custom'),
    churnAnalysis: path(ROOTS_DASHBOARD, '/churn-analysis'),
    fullAnalysis: path(ROOTS_DASHBOARD, '/full-analysis'),
    cohortAnalysis: path(ROOTS_DASHBOARD, '/cohort-analysis'),
    lostCustomers: path(ROOTS_DASHBOARD, '/lost-customers'),
    monthlyTrends: path(ROOTS_DASHBOARD, '/monthly-trends'),
    all: path(ROOTS_DASHBOARD, '/all'),
    paidAds: path(ROOTS_DASHBOARD, '/paid-ads'),
    adCreatives: path(ROOTS_DASHBOARD, '/ad-creatives'),
    marketingEfficiency: path(ROOTS_DASHBOARD, '/marketing-efficiency'),
    compareKpi: path(ROOTS_DASHBOARD, '/kpi'),
    funnel: path(ROOTS_DASHBOARD, '/funnel'),
    newCustomers: path(ROOTS_DASHBOARD, '/new-customers'),
    returningCustomers: path(ROOTS_DASHBOARD, '/returning-customers'),
    discountCodes: path(ROOTS_DASHBOARD, '/discount-codes'),
    adsPerformance: path(ROOTS_DASHBOARD, '/ads-performance'),
    adsCreativePerformance: path(ROOTS_DASHBOARD, '/ads-creative-performance'),
    topSellers: path(ROOTS_DASHBOARD, '/top-sellers'),
    repurchaseRates: path(ROOTS_DASHBOARD, '/repurchase-rates'),
    checklist: path(ROOTS_DASHBOARD, '/checklist'),
    integrations: path(ROOTS_DASHBOARD, '/integrations'),
    ltvTrends: path(ROOTS_DASHBOARD, '/ltv-trends'),
    utmBuilder: path(ROOTS_DASHBOARD, '/utm-builder'),
    utmValidator: path(ROOTS_DASHBOARD, '/utm-validator'),
    storeSettings: path(ROOTS_DASHBOARD, '/store-settings'),
    benchmarking: path(ROOTS_DASHBOARD, '/benchmarking'),
    landingPage: path(ROOTS_DASHBOARD, '/landing-page'),
    productDetailPage: path(ROOTS_DASHBOARD, '/product-detail-page'),
    convRateSource: path(ROOTS_DASHBOARD, '/conv-rate-source'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
