// routes
import { PATH_DASHBOARD } from '@/routes/paths';
// components

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Today',
    path: PATH_DASHBOARD.main.liveOrders,
    collapsed: false,
  },
  {
    title: 'KPIs',
    path: null,
    children: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.main.dashboardKpi,
      },
      {
        title: 'Trend Analysis',
        path: PATH_DASHBOARD.main.compareKpi,
      },
      {
        title: 'Benchmarking',
        path: PATH_DASHBOARD.main.benchmarking,
      },
      {
        title: 'Marketing Efficiency',
        path: PATH_DASHBOARD.main.marketingEfficiency,
      },
    ],
    collapsed: false,
  },
  {
    title: 'Attribution',
    path: null,
    children: [
      {
        title: 'Ads - PPC',
        path: PATH_DASHBOARD.main.paidAds,
      },
      {
        title: 'All Sources',
        path: PATH_DASHBOARD.main.all,
      },
      {
        title: 'New Customer Funnel',
        path: PATH_DASHBOARD.main.funnel,
      },
    ],
    collapsed: false,
  },
  {
    title: 'Conversion Rate',
    path: null,
    children: [
      {
        title: 'Landing Pages',
        path: PATH_DASHBOARD.main.landingPage,
      },
      {
        title: 'Product Detail Pages',
        path: PATH_DASHBOARD.main.productDetailPage,
      },
      {
        title: 'By Sources',
        path: PATH_DASHBOARD.main.convRateSource,
      },
    ],
    collapsed: false,
  },
  {
    title: 'LTV / Retention',
    path: null,
    children: [
      {
        title: 'LTV Trends',
        path: PATH_DASHBOARD.main.ltvTrends,
      },
      {
        title: 'Active Customers',
        path: PATH_DASHBOARD.main.monthlyTrends,
      },
      {
        title: 'Lost Customers',
        path: PATH_DASHBOARD.main.lostCustomers,
      },
    ],
    collapsed: true,
  },
  {
    title: 'Settings',
    path: null,
    children: [
      // {
      //   title: 'Checklist',
      //   path: PATH_DASHBOARD.main.checklist,
      // },
      {
        title: 'Account Setup',
        path: PATH_DASHBOARD.main.integrations,
      },
      {
        title: 'UTM Builder',
        path: PATH_DASHBOARD.main.utmBuilder,
      },
      {
        title: 'UTM Validator',
        path: PATH_DASHBOARD.main.utmValidator,
      },
      {
        title: 'Store Settings',
        path: PATH_DASHBOARD.main.storeSettings,
      },
    ],
    collapsed: true,
  },
];

export default navConfig;
